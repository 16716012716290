import React from "react";
import useScrollController from "../../hooks/useScrollController";
import {
  StyledDescriptionBlock,
  StyledDescriptionBlockTitle,
  StyledDescriptionBlockBody,
  StyledDescriptionBlockButton,
} from "./style";

export default ({
  id,
  theme,
  title,
  subtitle,
  button,
  buttonLink,
}) => {
  const { scrollToCssSelector } = useScrollController();
  
  return (
    <StyledDescriptionBlock id={id} isWhite={theme === "white"}>
      <StyledDescriptionBlockTitle dangerouslySetInnerHTML={{ __html: title }}/>
      <StyledDescriptionBlockBody dangerouslySetInnerHTML={{ __html: subtitle }}/>
      <StyledDescriptionBlockButton 
        dangerouslySetInnerHTML={{ __html: button }} 
        onClick={() => scrollToCssSelector(buttonLink, 84)}/>
    </StyledDescriptionBlock>
  );
};
